/* Copyright Levelise Ltd 2023 - 2024 */
import React, { useState, useRef } from 'react';
import cx from 'classnames';
import '../AggFacilities/index.css';
import './index.css';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Button, SpecInput } from '../Form';
import { useMemo } from 'react';
import { useCallback } from 'react';
import ClickableListItem from '../ClickableListItem';

const CustomerList = () => {
	const navigate = useNavigate();

	const context = useContext(FleetContext);

	const [loading, setLoading] = useState(false);

	const ref = useRef(0);

	const onCustomerClicked = useCallback(
		(customerId, onNewTab = false) => {
			if (customerId) {
				context.setCustomerSelectedInfoTab('Info');

				if (onNewTab) {
					window.open(`/customer/${customerId}`, '_blank');
				} else {
					navigate(`/customer/${customerId}`);
				}
			}
		},
		[navigate]
	);

	const getCustomersList = useCallback(
		(customers) => {
			if (customers && customers?.length > 0) {
				return customers?.map((customer, index) => (
					<ClickableListItem className="customer-list-item" functionArg={customer?.customerId} clickHandler={onCustomerClicked} key={index}>
						<span className="strong customer">
							{customer?.name} {customer?.surname}
						</span>
						<span className="subtitle customer">{customer?.address1}</span>
						<span className="subtitle customer">{customer?.city}</span>
					</ClickableListItem>
				));
			}
		},
		[onCustomerClicked]
	);

	const customersList = useMemo(() => getCustomersList(context.customers), [context.customers, getCustomersList]);

	const onSearchClicked = async () => {
		setLoading(true);
		try {
			context.clearCustomers();
			const res = await FleetService.getUserCustomers(
				UserService.getUsername(),
				context.customerIds,
				context.customerSearchTerm
			);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				context.setCustomers(res.customers);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const onClearClicked = () => {
		context.clearCustomers();
		context.clearCustomerIds();
		context.clearCustomerSearchTerm();
	};

	const onIdsChange = (e) => {
		context.setCustomerIds(e.target.value);
	};

	const onSearchTermChange = (e) => {
		context.setCustomerSearchTerm(e.target.value);
	};

	return (
		<div className="aggFacilities-wrapper customer-list-wrapper">
			<div
				className="fleet-table-title"
				style={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<h2>Customers</h2>
			</div>
			<div className="search-inputs-container">
				<div className="label-input-container">
					<label htmlFor="idInput">ID</label>
					<SpecInput
						id="customer-id-input"
						name="idInput"
						type="text"
						placeholder="Customer ID e.g. 12"
						value={context.customerIds}
						onChange={onIdsChange}
					/>
				</div>
				<div className="label-input-container">
					<label htmlFor="searchInput">Search</label>
					<SpecInput
						id="customer-info-input"
						name="searchInput"
						type="text"
						placeholder="Name/Address/Email/Phone"
						value={context.customerSearchTerm}
						onChange={onSearchTermChange}
					/>
				</div>
				<div className="customer-search-button-container">
					<Button className="configure-btn reset" onClick={onClearClicked}>
						Clear
					</Button>
					<Button
						className={cx('configure-btn submit')}
						disabled={loading || !(context.customerIds || context.customerSearchTerm)}
						onClick={onSearchClicked}
					>
						Search
					</Button>
				</div>
			</div>
			<ul ref={ref} className={cx('aggFacilities')}>
				{customersList}
				{context.customers?.length ? (
					<li
						key={'lastCustomer'}
						style={{
							cursor: 'pointer',
						}}
					>
						<span className="strong customer"></span>
					</li>
				) : null}
			</ul>
		</div>
	);
};

export default CustomerList;
