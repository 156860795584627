/* Copyright Levelise Ltd 2024 */
import React, { useState, useRef } from 'react';
import '../CustomerList/index.css';
import './index.css';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Button, SpecInput } from '../Form';
import { useMemo } from 'react';
import { useCallback } from 'react';
import TabContainer from '../TabContainer';
import AddBtn from '../AddBtn';
import { useAlert } from 'react-alert';
import ClickableListItem from '../ClickableListItem';

const FleetCustomerList = () => {
	const navigate = useNavigate();

	const alert = useAlert();

	const fleetContext = useContext(FleetContext);

	const [loading, setLoading] = useState(false);

	const ref = useRef(0);

	const setCustomerIds = (txt) => fleetContext.setFleetCustomerIds(txt);
	const setCustomerSearchTerm = (txt) => fleetContext.setFleetCustomerSearchTerm(txt);
	const setCustomers = (arr) => fleetContext.setFleetCustomersList(arr);

	const onCustomerClicked = useCallback(
		(customerId, onNewTab = false) => {
			if (customerId) {
				fleetContext.setCustomerSelectedInfoTab('Info');

				if (onNewTab) {
					window.open(`/customer/${customerId}`, '_blank');
				} else {
					navigate(`/customer/${customerId}`);
				}
			}
		},
		[navigate]
	);

	const onDruSelected = useCallback(
		async (dru, onNewTab = false) => {
			if (typeof dru === 'undefined' || dru === null || dru === '') {
				return;
			}

			let facilityName = '';

			try {
				const facility = await FleetService.getFacilityByDruId(dru);
				if (facility && Object.hasOwn(facility, 'name')) {
					facilityName = facility.name;
					const customer = await FleetService.getCustomerByFacility(facility.name);
					if (customer) {
						if (onNewTab) {
							window.open(`/customer/${customer?.customerId}`, '_blank')
						} else {
							navigate(`/customer/${customer?.customerId}`);
						}
					}
				} else {
					alert.show("Couldn't find selected dru's facility information.");
				}
			} catch (err) {
				if (err.status === 404 && err.message === `No customer was found for facility ${facilityName}`) {
					alert.show("Couldn't find selected facility's customer.");
				} else {
					alert.show('Something went wrong, please try again.');
				}
			}
		},
		[navigate]
	);

	const getCustomersList = useCallback(() => {
		if (fleetContext && fleetContext?.fleetCustomersList && fleetContext?.fleetCustomersList?.length > 0) {
			return fleetContext.fleetCustomersList?.map((customer, index) => (
				<ClickableListItem className="customer-list-item" functionArg={customer?.customerId} clickHandler={onCustomerClicked} key={index}>
					<span className="strong customer">
						{customer?.name} {customer?.surname}
					</span>
					<span className="subtitle customer">{customer?.address1}</span>
					<span className="subtitle customer">{customer?.city}</span>
				</ClickableListItem>
			));
		}
	}, [onCustomerClicked, fleetContext]);

	const getCustomerDrusList = useCallback(
		(drus) => {
			if (drus && drus?.length > 0) {
				return drus?.map((dru, index) => (
					<ClickableListItem className="customer-list-item" functionArg={parseInt(dru)} clickHandler={onDruSelected} key={`${index}dru`}>
						<span className="strong customer">
							{dru}
						</span>
					</ClickableListItem>
				));
			}
		},
		[onDruSelected]
	);

	const customersList = useMemo(() => getCustomersList(), [getCustomersList]);

	const drusList = useMemo(
		() => getCustomerDrusList(fleetContext.customerDrusRes),
		[fleetContext.customerDrusRes, getCustomerDrusList]
	);

	const includes = (dru) => {
		if (!fleetContext.customerSearchDruId.length) return false;
		// else...
		const match = fleetContext.customerSearchDruId.toLowerCase();

		if (String(dru).includes(match)) return true;

		return false;
	};

	const getList = (druList) => {
		if (druList && druList.length) {
			const filteredList = druList.filter((dru) => includes(dru)).sort((a, b) => parseInt(a) - parseInt(b));
			const cleanList = Array.from(new Set(filteredList));
			return cleanList;
		}

		return [];
	};

	const onSearchClicked = async () => {
		setLoading(true);

		if (fleetContext.customerSearchDruId.length > 0) {
			const listOfDru = getList(fleetContext.currentDrusForCustomerSearch);
			fleetContext.setCustomerDrusRes(listOfDru);
			fleetContext.setCustomerSearchShowDrusList(true);
			setLoading(false);

			return;
		}

		try {
			setCustomers([]);
			fleetContext.setCustomerSearchShowDrusList(false);

			const res = await FleetService.getUserCustomers(
				UserService.getUsername(),
				fleetContext.fleetCustomerIds,
				fleetContext.fleetCustomerSearchTerm
			);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				setCustomers(res.customers);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const onClearClicked = () => {
		setCustomers([]);
		setCustomerIds('');
		setCustomerSearchTerm('');
		fleetContext.clearCustomerDrusRes();
		fleetContext.clearCustomerSearchDruId();
		fleetContext.setCustomerSearchShowDrusList(false);
	};

	const onIdsChange = (e) => {
		setCustomerIds(e.target.value);
	};

	const onSearchTermChange = (e) => {
		setCustomerSearchTerm(e.target.value);
	};

	const onSearchDruIdChange = (e) => {
		fleetContext.setCustomerSearchDruId(e.target.value);
	};

	const onAddCustomerClick = () => {
		navigate('/add-customer');
	};

	return (
		<TabContainer title="Customers" rightCornerComponent={<AddBtn onAddClick={onAddCustomerClick} />}>
			<div className="search-inputs-container">
				<div className="label-input-container label-input-container-on-fleet-customer">
					<label htmlFor="idInput">Customer ID</label>
					<SpecInput
						id="customer-id-input"
						name="idInput"
						type="text"
						placeholder="ID e.g. 10"
						value={fleetContext.fleetCustomerIds}
						onChange={onIdsChange}
						disabled={fleetContext.customerSearchDruId.length > 0}
					/>
				</div>
				<div className="label-input-container label-input-container-on-fleet-customer">
					<label htmlFor="searchInput">Search</label>
					<SpecInput
						id="customer-info-input"
						name="searchInput"
						type="text"
						placeholder="Name/Address/Email/Phone"
						value={fleetContext.fleetCustomerSearchTerm}
						onChange={onSearchTermChange}
						disabled={fleetContext.customerSearchDruId.length > 0}
					/>
				</div>
				<div className="label-input-container label-input-container-on-fleet-customer">
					<label htmlFor="searchInput">DRU ID</label>
					<SpecInput
						id="customer-info-input-dru"
						name="searchInputDruIs"
						type="number"
						placeholder="ID e.g. 10012345"
						value={fleetContext.customerSearchDruId}
						onChange={onSearchDruIdChange}
						disabled={fleetContext.fleetCustomerIds || fleetContext.fleetCustomerSearchTerm}
					/>
				</div>
				<div className="customer-search-button-container">
					<Button className="configure-btn reset" onClick={onClearClicked}>
						Clear
					</Button>
					<Button
						className={'configure-btn submit'}
						disabled={
							loading ||
							!(
								fleetContext.fleetCustomerIds ||
								fleetContext.fleetCustomerSearchTerm ||
								fleetContext.customerSearchDruId
							)
						}
						onClick={onSearchClicked}
					>
						Search
					</Button>
				</div>
			</div>
			<ul ref={ref} className={'fleet-customer-list'} data-cy="fleet-customer-search-results">
				{!fleetContext.customerSearchShowDrusList ? <>{customersList}</> : null}

				{fleetContext.customerDrusRes.length > 0 && fleetContext.customerSearchShowDrusList ? drusList : null}
				<li
					key={'lastCustomer'}
					style={{
						cursor: 'pointer',
					}}
				>
					<span className="strong customer"></span>
				</li>
			</ul>
		</TabContainer>
	);
};

export default FleetCustomerList;
