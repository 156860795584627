/* Copyright Levelise Ltd 2024 */
import React, { useEffect, useState } from 'react';
import TabContainer from '../TabContainer';
import './index.css';
import { hasPermission, validateEmail, validatePhoneNumber } from '../../utils/utils';
import { PERMISSIONS } from '../../utils/constants';
import { useAlert } from 'react-alert';
import { Button, SpecInput } from '../Form';
import { colors } from '../../utils/chart';

const oldCustomer = 'Old Customer';
const newCustomer = 'New Customer';
const propertyDeveloper = 'Property Developer';

const sale = 'Sale';
const tenancy = 'Tenancy';
const deceased = 'Deceased Customer';

function InputWithErrorMessage({ value, onValueChange, placeholder, label, error, textInputStyle, ...props }) {
	return (
		<div className="add-customer-label-input-container">
			<label
				htmlFor="searchInput"
				className="label-col"
				style={{
					marginBottom: error ? 10 : 0,
				}}
			>
				{label}
			</label>
			<div
				className="input-col"
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				}}
			>
				<SpecInput
					name="searchInput"
					type="text"
					placeholder={placeholder}
					value={value}
					onChange={onValueChange}
					style={textInputStyle}
					{...props}
				/>
				{error ? (
					<span
						style={{
							fontSize: 9,
							color: colors.errorRed,
							textAlign: 'start',
							wordBreak: 'break-word',
						}}
					>
						{error}
					</span>
				) : null}
			</div>
		</div>
	);
}

function NewCustomerTabCoT({ customer, facility, handleChangeTenant }) {
	const alert = useAlert();

	const [isCustomerKnown, setIsCustomerKnown] = useState(true);

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [movingDate, setMovingDate] = useState('');
	const [notifiedBy, setNotifiedBy] = useState(newCustomer);
	const [reasonForMoving, setReasonForMoving] = useState(tenancy);

	const [emailError, setEmailError] = useState('');
	const [phoneError, setPhoneError] = useState('');

	const [emptyFieldsObj, setEmptyFieldsObj] = useState({});

	const onFirstNameChange = (e) => {
		if (e.target.value !== '') {
			setEmptyFieldsObj({ ...emptyFieldsObj, name: false });
		}
		setName(e.target.value);
	};

	const onSurnameChange = (e) => {
		if (e.target.value !== '') {
			setEmptyFieldsObj({ ...emptyFieldsObj, surname: false });
		}
		setSurname(e.target.value);
	};

	const onEmailChange = (e) => {
		if (e.target.value !== '') {
			setEmptyFieldsObj({ ...emptyFieldsObj, email: false });
		}

		if (emailError) {
			setEmailError('');
		}
		setEmail(e.target.value);
	};

	const onPhoneChange = (e) => {
		if (e.target.value !== '') {
			setEmptyFieldsObj({ ...emptyFieldsObj, phone: false });
		}
		if (phoneError) {
			setPhoneError('');
		}
		setPhone(e.target.value);
	};

	const onMovingDateChange = (e) => {
		setMovingDate(e.target.value);
	};

	const onNotifiedByChange = (e) => {
		setNotifiedBy(e.target.value);
	};

	const onReasonForMovingChange = (e) => {
		setReasonForMoving(e.target.value);
	};

	const resetState = () => {
		setName('');
		setSurname('');
		setEmail('');
		setPhone('');
		setMovingDate('');
		setNotifiedBy(newCustomer);
		setReasonForMoving(tenancy);

		setEmailError('');
		setPhoneError('');

		setEmptyFieldsObj({});
	};

	const onClearClicked = (e) => {
		resetState();
	};

	const checkForRequiredFields = () => {
		if (isCustomerKnown) {
			if (!name) {
				setEmptyFieldsObj({ ...emptyFieldsObj, name: true });
				return false;
			}

			if (!surname) {
				setEmptyFieldsObj({ ...emptyFieldsObj, surname: true });
				return false;
			}

			if (!email) {
				setEmptyFieldsObj({ ...emptyFieldsObj, email: true });
				return false;
			}

			if (!phone) {
				setEmptyFieldsObj({ ...emptyFieldsObj, phone: true });
				return false;
			}
		}

		if (!movingDate) {
			setEmptyFieldsObj({ ...emptyFieldsObj, movingDate: true });
			return false;
		}

		return true;
	};

	const onSubmitClicked = (e) => {
		const allFieldsFilled = checkForRequiredFields();
		if (!allFieldsFilled) {
			return;
		}

		if (isCustomerKnown) {
			const isEmailValid = validateEmail(email);
			if (!isEmailValid) {
				setEmailError('Please provide a valid email.');
				return;
			}

			if (!validatePhoneNumber(phone)) {
				setPhoneError('Please provide a valid phone number.');
				return;
			}
		}

		if (
			hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER) &&
			facility &&
			Object.hasOwn(facility, 'druId') &&
			customer &&
			Object.hasOwn(customer, 'customerId')
		) {
			handleChangeTenant({
				forename: name,
				surname,
				email,
				phone,
				address1: customer?.address1 || '',
				prevCustomerId: customer.customerId,
				reason: reasonForMoving,
				notifier: notifiedBy,
				transferDate: movingDate,
				isCustomerKnown
			});
		} else {
			alert.show('Something went wrong. Please try again.');
		}
	};

	const onCustomerIsKnownChange = (e) => {
		resetState();
		setIsCustomerKnown(e.target.checked);
	};

	useEffect(() => {
		resetState();
	}, [facility, customer]);

	return (
		<TabContainer title="New customer" containerStyle={{ height: '100%'}}>
			<div className="add-customer-form-container">
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<div className="invert-pv-wrapper">
						<SpecInput
							type="checkbox"
							id="customerKnown"
							name="customerKnown"
							className="customerKnown"
							onChange={onCustomerIsKnownChange}
							checked={isCustomerKnown}
						/>
						<span>New customer is known.</span>
					</div>
				</div>
				{isCustomerKnown ? (
					<>
						<div className="add-customer-label-input-container">
							<label htmlFor="searchInput" className="label-col">
								Name
							</label>
							<SpecInput
								name="searchInput"
								type="text"
								placeholder="Name"
								value={name}
								onChange={onFirstNameChange}
								style={{
									borderColor: emptyFieldsObj?.name ? '#a81916' : '#808080',
								}}
								className="input-col"
							/>
						</div>
						<div className="add-customer-label-input-container">
							<label htmlFor="searchInput" className="label-col">
								Surname
							</label>
							<SpecInput
								name="searchInput"
								type="text"
								placeholder="Surname"
								value={surname}
								onChange={onSurnameChange}
								style={{
									borderColor: emptyFieldsObj?.surname ? '#a81916' : '#808080',
								}}
								className="input-col"
							/>
						</div>
						<InputWithErrorMessage
							placeholder="example@levelise.com"
							value={email}
							onValueChange={onEmailChange}
							label="Email"
							error={emailError}
							textInputStyle={{
								borderColor: emptyFieldsObj?.email ? '#a81916' : '#808080',
							}}
							type="email"
						/>

						<InputWithErrorMessage
							placeholder="Eg. 07XXXXXXXXX"
							value={phone}
							onValueChange={onPhoneChange}
							label="Phone"
							error={phoneError}
							textInputStyle={{
								borderColor: emptyFieldsObj?.phone ? '#a81916' : '#808080',
							}}
						/>
					</>
				) : null}
				<div className="add-customer-label-input-container">
					<label htmlFor="searchInput" className="label-col">
						Move In Date
					</label>
					<SpecInput
						className="date-input input-col"
						name="searchInput"
						type="date"
						placeholder="DD/MM/YYYY"
						value={movingDate}
						onChange={onMovingDateChange}
						style={{
							borderColor: emptyFieldsObj?.movingDate ? '#a81916' : '#808080',
						}}
					/>
				</div>

				<div className="add-customer-label-input-container" style={{ alignItems: 'start' }}>
					<label htmlFor="searchInput" className="label-col">
						Reason for change
					</label>
					<div style={{ minWidth: 162, maxWidth: 170 }} className="input-col">
						<label className="radio-input-label">
							<SpecInput
								checked={reasonForMoving === tenancy}
								className="date-input"
								name="reasonForChange"
								type="radio"
								value={tenancy}
								onChange={onReasonForMovingChange}
								style={{
									minWidth: 'unset',
								}}
								id={tenancy}
							/>
							<label htmlFor={tenancy} style={{ fontFamily: 'Inter-Light' }}>
								{tenancy}
							</label>
						</label>
						<label className="radio-input-label">
							<SpecInput
								checked={reasonForMoving === sale}
								className="date-input"
								name="reasonForChange"
								type="radio"
								value={sale}
								onChange={onReasonForMovingChange}
								style={{
									minWidth: 'unset',
								}}
								id={sale}
							/>
							<label htmlFor={sale} style={{ fontFamily: 'Inter-Light' }}>
								{sale}
							</label>
						</label>
						<label className="radio-input-label">
							<SpecInput
								checked={reasonForMoving === deceased}
								className="date-input"
								name="reasonForChange"
								type="radio"
								value={deceased}
								onChange={onReasonForMovingChange}
								style={{
									minWidth: 'unset',
								}}
								id={deceased}
							/>
							<label htmlFor={deceased} style={{ fontFamily: 'Inter-Light' }}>
								{deceased}
							</label>
						</label>
					</div>
				</div>

				<div className="add-customer-label-input-container" style={{ alignItems: 'start' }}>
					<label htmlFor="searchInput" className="label-col">
						Notified by
					</label>
					<div style={{ minWidth: 162, maxWidth: 170 }} className="input-col">
						<label className="radio-input-label">
							<SpecInput
								checked={notifiedBy === newCustomer}
								className="date-input"
								name="notifiedBy"
								type="radio"
								value={newCustomer}
								onChange={onNotifiedByChange}
								style={{
									minWidth: 'unset',
								}}
								id={newCustomer}
							/>
							<label htmlFor={newCustomer} style={{ fontFamily: 'Inter-Light' }}>
								{newCustomer}
							</label>
						</label>
						<label className="radio-input-label">
							<SpecInput
								checked={notifiedBy === oldCustomer}
								className="date-input"
								name="notifiedBy"
								type="radio"
								value={oldCustomer}
								onChange={onNotifiedByChange}
								style={{
									minWidth: 'unset',
								}}
								id={oldCustomer}
							/>
							<label htmlFor={oldCustomer} style={{ fontFamily: 'Inter-Light' }}>
								{oldCustomer}
							</label>
						</label>
						<label className="radio-input-label">
							<SpecInput
								checked={notifiedBy === propertyDeveloper}
								className="date-input"
								name="notifiedBy"
								type="radio"
								value={propertyDeveloper}
								onChange={onNotifiedByChange}
								style={{
									minWidth: 'unset',
								}}
								id={propertyDeveloper}
							/>
							<label htmlFor={propertyDeveloper} style={{ fontFamily: 'Inter-Light' }}>
								{propertyDeveloper}
							</label>
						</label>
					</div>
				</div>

				<div className="add-customer-label-input-container">
					<label className="label-col"></label>
					<div className="add-customer-buttons-container input-col">
						<Button className="configure-btn reset" onClick={onClearClicked}>
							Clear
						</Button>
						<Button
							className={'configure-btn submit'}
							disabled={!facility || !customer}
							onClick={onSubmitClicked}
						>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</TabContainer>
	);
}

export default NewCustomerTabCoT;
