/* Copyright Levelise Ltd 2023-2024 */
import React, { useCallback, useState } from 'react';
import './index.css';
import NavBar from '../../components/NavBar';
import {
	PERMISSIONS,
	acceptEvidenceConfirmationText,
	download,
	loading,
	routes,
	upload,
	withdrawConsentConfirmationText,
	withdrawDSRConfirmationText,
} from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FleetService from '../../services/fleet-service';
import CusotmerCredits from '../../components/CustomerCredits';
import { useContext } from 'react';
import FacilityContext from '../../contexts/FacilityContext';
import FleetConext from '../../contexts/FleetContext';
import { hasPermission } from '../../utils/utils';
import CustomerInformationSection from '../../components/CustomerInformationSection';
import UserService from '../../services/user-service';
import CustomerUsersSection from '../../components/CustomerUsersSection/CustomerUsersSection';
import CustomerConsentSection from '../../components/CustomerConsentSection';
import Popup from '../../components/Popup';
import CustomerBankingDetailsSection from '../../components/CustomerBankingDetailSection';
import { useAlert } from 'react-alert';

const ACCEPT_EVIDENCE_BUTTON_NAME = 'Accept Evidence';
const WITHDRAW_CONSENT_BUTTON_NAME = 'Withdraw Consent';
const WITHDRAW_DSR = 'Withdraw DSR';

function CustomerRoute() {
	const navigate = useNavigate();

	const alert = useAlert();

	const params = useParams();

	const facilityContext = useContext(FacilityContext);
	const fleetContext = useContext(FleetConext);

	const [customer, setCustomer] = useState(null);

	const [customerFacilities, setCustomerFacilities] = useState(null);
	const [customerConsent, setCustomerConsent] = useState(null);
	const [bankingInfo, setBankingInfo] = useState(null);

	const [customerImportTariff, setCustomerImportTariff] = useState(null);
	const [customerExportTariff, setCustomerExportTariff] = useState(null);

	const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
	const [consentEditing, setConsentEditing] = useState(false);
	const [loadingConsentEdit, setLoadingConsentEdit] = useState(false);

	const [downloadState, setDownloadState] = useState(download);
	const [uploadState, setUploadState] = useState(upload);

	const [clickedButton, setClickedButton] = useState('');

	const handleTabClickForCustomerInfo = (selectedView) => {
		fleetContext.setCustomerSelectedInfoTab(selectedView);
	};

	const handleFetchSpecification = (facility) => {
		if (!!facility && facility !== 'undefined') {
			FleetService.getFacility(facility)
				.then((res) => {
					if (Object.keys(res).length > 1) {
						facilityContext.setFacility(res);
						navigate(`/facility/${facility}`);
					} else {
						// NOTE: Response body only contains DRU ID if specification is not set
						alert.show('Facility not found');
					}
				})
				.catch(facilityContext.setError);
		}
	};

	const updateCustomer = (customer) => {
		fleetContext.setSelectedCustomer(customer);
	};

	const updateCustomerUsers = (users) => {
		fleetContext.setCustomerUsers(users);
	};

	const fetchCustomerInformation = useCallback(async (customerId) => {
		if (!customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
			return;
		}
		updateCustomer(null);
		try {
			const res = await FleetService.getUserCustomers(UserService.getUsername(), customerId);

			if (res && res?.customers && res.customers.length) {
				updateCustomer(res.customers[0]);
			}
		} catch (err) {
			console.log('In fetch customer info: ', err);
		}
	}, []);

	const fetchCustomerUsers = useCallback(async (customerId) => {
		if (!customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
			return;
		}
		updateCustomerUsers([]);
		try {
			const res = await FleetService.getCustomerUsers(customerId);

			if (res && res?.users && res.users.length) {
				updateCustomerUsers(res.users);
			}
		} catch (err) {
			console.log('In fetch customer info: ', err);
		}
	}, []);

	const fetchCustomerFacilities = useCallback(async (customerId) => {
		if (!customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER_FACILITIES)) {
			return;
		}

		let errorOccured = false;
		try {
			const res = await FleetService.getCustomerFacilities(customerId);
			if (res && Object.hasOwn(res, 'facilities')) {
				const facilities = res.facilities;
				const newFaciltiesArr = [];
				let i = 0;
				while (i < facilities.length && !errorOccured) {
					if (facilities[i] && Object.hasOwn(facilities[i], 'name')) {
						const facilitySpec = await FleetService.getFacility(facilities[i].name);
						if (facilitySpec) {
							const obj = { ...facilitySpec, ...facilities[i] };
							newFaciltiesArr.push(obj);
						}
					}
					i++;
				}

				setCustomerFacilities(newFaciltiesArr);
			}
		} catch (err) {
			errorOccured = true;
			console.log('In fetch customer facility: ', err);
		}
	}, []);

	const getImportTariff = async (customerId) => {
        const importTariff = await FleetService.getCustomerTariff(customerId, 'import');
        if (importTariff) {
            setCustomerImportTariff(importTariff);
        }
    };

    const getExportTariff = async (customerId) => {
        const exportTariff = await FleetService.getCustomerTariff(customerId, 'export');
        if (exportTariff) {
            setCustomerExportTariff(exportTariff);
        }
    };

	const fetchCustomerTariffs = useCallback(async (customerId) => {
		if (!customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER)) {
			return;
		}

		try {
			await getImportTariff(customerId);

			await getExportTariff(customerId);
		} catch (err) {
			console.log('In fetch customer tariff: ', err);
		}
	}, []);

	const fetchCustomerConsent = useCallback(async (customerId) => {
		if (!customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER_CONSENT)) {
			return;
		}

		try {
			const res = await FleetService.getCustomerConsents(customerId);

			if (res) {
				setCustomerConsent(res);
			}
		} catch (err) {
			console.log('In fetch consents: ', err);
		}
	}, []);

	const fetchCustomerBankingInfo = useCallback(async (customerId) => {
		if (!customerId || !hasPermission(PERMISSIONS.CAN_ACCESS_CUSTOMER_CONSENT)) {
			return;
		}

		try {
			const res = await FleetService.getCustomerBankingInformation(customerId);

			if (res) {
				setBankingInfo(res);
			}
		} catch (err) {
			console.log('In fetch consents: ', err);
		}
	}, []);

	const handleUploadBill = async (file) => {
		if (
			file &&
			hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
			customer &&
			customer?.customerId &&
			customerConsent &&
			Object.hasOwn(customerConsent, 'consentId')
		) {
			try {
				const type = file?.type;

				const typeObject = { contentType: type };

				if (type) {
					const res = await FleetService.uploadCustomerBill(
						customer?.customerId,
						customerConsent.consentId,
						typeObject
					);

					if (res && res?.success && Object.hasOwn(res, 'uri')) {
						const s3Res = await FleetService.uploadBillToS3(res.uri, res?.method, file);

						if (s3Res) {
							await fetchCustomerConsent(customer?.customerId);
						}
					}
				}
			} catch (error) {
				Promise.reject(error);
			}
		}
	};

	const handleDownloadBill = async () => {
		if (
			hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
			customer &&
			customer?.customerId &&
			customerConsent &&
			Object.hasOwn(customerConsent, 'consentId')
		) {
			setDownloadState(loading);
			let url;
			try {
				const res = await FleetService.getCustomerBill(customer.customerId, customerConsent.consentId);

				if (res && res?.success && Object.hasOwn(res, 'uri')) {
					const response = await fetch(res.uri);

					if (!response.ok) {
						throw new Error('Network response was not OK');
					}

					const reader = response.body.getReader();
					const chunks = [];
					let done = false;

					while (!done) {
						const { value, done: readerDone } = await reader.read();
						done = readerDone;
						if (value) {
							chunks.push(value);
						}
					}

					const blob = new Blob(chunks, { type: response.headers.get('Content-Type') });

					url = URL.createObjectURL(blob);

					window.open(url, '_blank');
				}

				if (url) {
					URL.revokeObjectURL(url);
				}

				setDownloadState(download);
			} catch (err) {
				setDownloadState(download);
			}
		}
	};

	const showConfirmationModal = (btn) => {
		setClickedButton(btn);
		setConfirmationModalVisible(true);
	};

	const handleAcceptEvidenceClick = () => {
		showConfirmationModal(ACCEPT_EVIDENCE_BUTTON_NAME);
	};
	const handleCloseModalClick = () => {
		setConfirmationModalVisible(false);
	};

	const handleConfirmEvidenceAcceptance = async () => {
		if (
			hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
			customer &&
			customer?.customerId &&
			customerConsent &&
			Object.hasOwn(customerConsent, 'consentId')
		) {
			setLoadingConsentEdit(true);
			try {
				const resMessage = await FleetService.acceptCustomerEvidence(
					customer.customerId,
					customerConsent.consentId
				);

				if (resMessage) {
					await fetchCustomerConsent(customer?.customerId);

					setConfirmationModalVisible(false);
				}
				setConfirmationModalVisible(false);
				setLoadingConsentEdit(false);
				setConsentEditing(false);
			} catch (err) {
				alert.show('Something went wrong. Please try again.');
				setLoadingConsentEdit(false);
			}
		}
	};

	const handleConfirmWithdrawConsent = async () => {
		if (
			hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
			customer &&
			customer?.customerId
		) {
			setLoadingConsentEdit(true);
			try {
				const body = {
					gsaAgreed: false,
					hhCollectionAgreed: false,
					dfsAgreed: false,
				};

				const resMessage = await FleetService.updateConsent(customer.customerId, body);

				if (resMessage) {
					await fetchCustomerConsent(customer?.customerId);

					await fetchCustomerFacilities(customer.customerId);

					setConfirmationModalVisible(false);
				}
				setConfirmationModalVisible(false);
				setLoadingConsentEdit(false);
				setConsentEditing(false);
			} catch (err) {
				alert.show('Something went wrong. Please try again.');
				setLoadingConsentEdit(false);
			}
		}
	};

	const handleConfirmWithdrawDSR = async () => {
		if (
			hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) &&
			customer &&
			customer?.customerId &&
			customerFacilities &&
			customerFacilities.length > 0
		) {
			setLoadingConsentEdit(true);
			try {
				const facility = customerFacilities[0];

				if (!facility || !facility?.name) {
					alert.show('Something went wrong. Please try again.');
					setLoadingConsentEdit(false);
					return;
				}

				const body = {
					drAllowed: false,
				};

				const res = await FleetService.setFacility(facility.name, body);

				if (res) {
					await fetchCustomerFacilities(customer.customerId);
				}

				setConfirmationModalVisible(false);
				setLoadingConsentEdit(false);
				setConsentEditing(false);
			} catch (err) {
				alert.show('Something went wrong. Please try again.');
				setLoadingConsentEdit(false);
			}
		}
	};

	const handleWithdrawConsentClick = () => {
		showConfirmationModal(WITHDRAW_CONSENT_BUTTON_NAME);
	};

	const handleWithdrawDSRClick = () => {
		showConfirmationModal(WITHDRAW_DSR);
	};

	const handleConfirmButtonClick = async () => {
		if (clickedButton === ACCEPT_EVIDENCE_BUTTON_NAME) {
			await handleConfirmEvidenceAcceptance();
		}

		if (clickedButton === WITHDRAW_CONSENT_BUTTON_NAME) {
			await handleConfirmWithdrawConsent();
		}

		if (clickedButton === WITHDRAW_DSR) {
			await handleConfirmWithdrawDSR();
		}
	};

	const fetchCustomer = async (customerId) => {
		try {
			const res = await FleetService.getUserCustomers(
				UserService.getUsername(),
				customerId
			);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				setCustomer(res.customers[0]);
			}

		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		const customerId = params.customerId;

		if (customerId && !isNaN(parseInt(customerId, 10)) && !fleetContext.tokenUpdating) {
			fetchCustomer(customerId);
		}
	}, [fleetContext.tokenUpdating]);

	useEffect(() => {
		if (customer && customer?.customerId && !fleetContext.tokenUpdating) {
			fetchCustomerInformation(customer.customerId);
			fetchCustomerUsers(customer.customerId);
			fetchCustomerBankingInfo(customer.customerId);
			fetchCustomerConsent(customer.customerId);
			fetchCustomerFacilities(customer.customerId);
			fetchCustomerTariffs(customer.customerId);
		}
	}, [customer, fleetContext.tokenUpdating]);

	return (
		<div>
			<NavBar route={routes.customer} openedFrom={'/'} />
			<div className="container">
				<div className="containt-container" data-cy="customer_info_user_container">
					<div className="tab-container-customer" data-cy="customer_info_container">
						{customer && (
							<CustomerInformationSection
								customer={fleetContext.selectedCustomer}
								customerUsers={fleetContext.customerUsers}
								view={fleetContext.customerSelectedInfoTab}
								setView={handleTabClickForCustomerInfo}
								customerFacilities={customerFacilities}
								handleFetchSpecification={handleFetchSpecification}
								updateCustomer={updateCustomer}
								updateCustomerUsers={updateCustomerUsers}
							/>
						)}
					</div>
					<div className="tab-container-customer users-tab-row" data-cy="customer_users_container">
						{customer && (
							<CustomerUsersSection
								customerUsers={fleetContext.customerUsers}
								customer={fleetContext.selectedCustomer}
								updateCustomer={updateCustomer}
								updateCustomerUsers={updateCustomerUsers}
							/>
						)}
					</div>
				</div>
				<div className="containt-container middle" data-cy="consent_tariff_container">
					<div className="tab-container-customer" data-cy="customer_consent_container">
						{customer && (
							<CustomerConsentSection
								handleAcceptEvidenceClick={handleAcceptEvidenceClick}
								handleWithdrawConsentClick={handleWithdrawConsentClick}
								handleWithdrawDSRClick={handleWithdrawDSRClick}
								customerConsent={customerConsent}
								editing={consentEditing}
								setEditing={setConsentEditing}
								facility={
									customerFacilities && customerFacilities?.length > 0 ? customerFacilities[0] : null
								}
								handleUploadBill={handleUploadBill}
								uploadState={uploadState}
								setUploadState={setUploadState}
								downloadState={downloadState}
								handleDownloadClick={handleDownloadBill}
								customerImportTariff={customerImportTariff}
								customerExportTariff={customerExportTariff}
							/>
						)}
					</div>
				</div>
				<div className="containt-container rightSide" data-cy="bank_details_credits_container">
					{customer && <CustomerBankingDetailsSection bankingInfo={bankingInfo} />}
					{customer && <CusotmerCredits customerId={customer?.customerId} />}
				</div>
			</div>
			{hasPermission(PERMISSIONS.CAN_EDIT_CUSTOMER_CONSENT) && confirmationModalVisible && (
				<Popup
					type="command"
					text={
						clickedButton === ACCEPT_EVIDENCE_BUTTON_NAME
							? acceptEvidenceConfirmationText
							: clickedButton === WITHDRAW_CONSENT_BUTTON_NAME
							? withdrawConsentConfirmationText
							: clickedButton === WITHDRAW_DSR
							? withdrawDSRConfirmationText
							: ''
					}
					onConfirm={handleConfirmButtonClick}
					confirm={'Confirm'}
					onClose={handleCloseModalClick}
					close={'Cancel'}
					textStyle={{
						marginTop: 0,
						padding: 10,
					}}
					disableConfirm={!customerConsent || !customer || loadingConsentEdit}
				/>
			)}
		</div>
	);
}

export default CustomerRoute;
